import styled from 'styled-components';
// import bgItem from 'assets/images/bag/bgItem.png';
import bgItemOdd from 'assets/images/bag/bg-item-odd.png';
import bgItemEven from 'assets/images/bag/bg-item-even.png';

export const Content = styled.div`
	width: 100%;
	overflow: auto;
	flex-grow: 1;
	z-index: 1;
`;

export const ContentOverflow = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 0 10vw;
	row-gap: 5px;
	@media (min-width: 425px) {
		padding: 0 2vw;
	}
	@media (max-width: 325px) {
		padding: 0 5vw;
		margin-bottom: 2rem;
	}
`;

export const ItemWrapper = styled.div`
	position: relative;
	::after {
		content: '';
		position: absolute;
		bottom: ${(props) => (props.rotated ? '0' : '0')};
		left: 35%;
		width: 30%;
		height: 3px;
		background-color: ${(props) => (props.active ? '#FECC1D' : '#c7c7ca')};
		border-radius: 3px;
		z-index: 100;
	}

	:nth-child(even) {
		div::before {
			background-image: url(${bgItemEven});
			background-position: center bottom;
			filter: drop-shadow(-5px -6px 6px rgba(24, 22, 43, 0.431));
			
		}
	}
	:nth-child(odd) {
		div::before {
			background-image: url(${bgItemOdd});
			background-position: center top;
			filter: drop-shadow(5px 6px 6px rgba(24, 22, 43, 0.431));
		}
	}

	img{
		${props=>!props.active && "filter: blur(3px);"}
	}
	span{
		${props=>!props.active && "filter: blur(3px);"}
	}
`;

export const Item = styled.div`
	height: 85px;
	width: 85px;
	&::before {
		content: '';
		inline-size: 85px;
		block-size: 90px;
		display: block;
		position: absolute;
		/* margin: 10px auto auto; */
		background-repeat: no-repeat;
		background-size: contain;
	}
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	/* gap: 5px; */
	img {
		inline-size: ${props => props.childrenImageSize};
		z-index: 15;
	}
	@media (min-width: 320px) {
		height: 90px;
		width: 90px;
	}
	@media (min-width: 400px) {
		height: 110px;
		width: 110px;
	}
`;