import React from "react";
import Icon from "./images/image.svg";
import { BgPrealoder, Figure } from "./styles";
import { motion } from "framer-motion";

export const PreloaderComponent = (_) => {
  return (
    <BgPrealoder>
      <motion.img
        animate={{ scale: [1,0.85,1] }}
        transition={{ repeat: Infinity }}
        src={Icon}
        alt="..."
      ></motion.img>
      {/* <Figure>
      </Figure> */}
    </BgPrealoder>
  );
};
