import { createGlobalStyle } from 'styled-components'
import "./assets/fonts/fonts-family.css"

const GlobalStyles = createGlobalStyle`
  .debugger {
    outline: 1px solid tomato;
    & * {
      outline: 1px solid tomato;
    }
  }

  html {
    scroll-behavior: smooth;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  body {
    inline-size: 100vw;
    block-size: 100vh;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }

  a{
    text-decoration: none;
  }

  :root {
    --general-padding: 19px;
  }
  
  .wrapper {
    padding: 9px var(--general-padding) 0 var(--general-padding);
  }

  .wrapper-x {
    padding: 0 var(--general-padding);
  }

  .d-none {
    display: none !important;
  }
`

export default GlobalStyles;