import styled from "styled-components";
// import CirclePlus from 'assets/images/provide-information/circle-plus.png'

const ImageStructure = styled.img.attrs((props) => {
  return {
    src: props.src || "...",
    alt: "Image not found",
    width: props.width,
    height: props.height,
  };
})`
  inline-size: ${(props) => props.width};
  block-size: ${(props) => props.height};
`;

export const Default = styled(ImageStructure)`
  @media(max-height: 600px){
    max-height: 120px;
  }
`;

export const ChatSizeAvatar = styled(ImageStructure)`
  inline-size: 25px;
  block-size: 25px;
  border-radius: 50%;
`;

export const ProvideInformation = styled(ImageStructure)`
  border-radius: 7px;
  inline-size: 100%;
`;
