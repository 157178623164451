import styled from "styled-components";

export const ButtonStyled = styled.button`
  --text-color: blue;
  --background-color: tomato;

  padding: ${(props) => props.padding || "12px"};
  &.btn-light {
    --text-color: #3e3262;
    --background-color: #ffffff;
  }
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: ${(props) => props.boxShadow || "unset"};
  border-radius: 11px;
  font-weight: 700;
  border: unset;
  width: ${(props) => props.width || "unset"};
`;

export const Normal = styled.button.attrs((props) => ({
  children: props.children || props.text || "Button",
  type: props.type || "button",
  disabled: props.disabled || false,
}))`
  font-family: "Poppins", sans-serif;
  border-radius: 9px;
  border: none;
  display: flex;
  align-items: center;
  font-size: 1em;
  gap: ${(props) => props.gap || "1rem"};
  padding: ${(props) => props.padding || "2vh 8vh"};
  text-align: center;
  background: ${(props) => props.background || "#fff"};
  box-shadow: ${(props) => props.boxShadow};
  color: ${(props) => props.color || "#3E3262"};
  font-weight: ${(props) => props.fontWeight || "700"};
  margin: ${(props) => props.margin || "0"};
  opacity: ${(props) => props.opacity};
  border-radius: ${(props) => props.borderRadius};
  opacity: ${(props) => (props.disabled ? "0.54" : "1")};
  position: ${(props) => props.position || "relative"};
  top: ${(props) => props.top || "unset"};
  left: ${(props) => props.left || "unset"};
  bottom: ${(props) => props.bottom || "unset"};
  right: ${(props) => props.right || "unset"};
  @media (min-width: 425px) {
    max-width: 400px;
  }
  svg {
    font-size: 1.5em;
  }
`;

export const NormalOutlined = styled(Normal)`
  background-color: transparent;
  border: ${(props) => props.border || "1px solid #3E3262"};
`;

export const Full = styled(Normal)`
  /* padding-inline: 38vw; */
  inline-size: ${(props) => props.inlineSize || "100%"};
  justify-content: center;
  padding: ${(props) => props.padding || "1rem 0"};
`;

export const FullCheckIn = styled(Full)`
  background: linear-gradient(90deg, #69a3fe 0%, #7e0bec 100%);
  box-shadow: 2px 7px 16px rgba(126, 11, 236, 0.25);
  color: #fff;
  /* inline-size: ${props=>props.width? props.width: "90vw"}; */
  margin: 2rem 0 1rem 0;
`;

export const FullOutlined = styled(Full)`
  background-color: transparent;
  border: ${(props) => props.border || "1px solid #3E3262"};
`;

export const Gost = styled(Normal)`
  background-color: transparent;
  border: none;
  font-weight: 700;
`;

export const FullYellow = styled(Full)`
  background: linear-gradient(90deg, #fecc1d 0%, #fdb700 100%);
  box-shadow: 2px 7px 16px rgba(253, 183, 0, 0.25);
  z-index:99;
`;

export const FullGreen = styled(Full)`
  background: linear-gradient(90deg, #04ffbe 0%, #01feff 100%);
  box-shadow: 2px 7px 16px rgba(4, 255, 190, 0.25);
`;

export const FullWhite = styled(Full)`
  background: #ffffff;
`;
