import axios from "axios"
import { API_VERSION } from "./config";

const StrengthService = {};

StrengthService.getAll = async () => {
  await axios.get('sanctum/csrf-cookie');
  const response = await axios.get(`${API_VERSION}strengths`);
  return response.data;
}


export default StrengthService;