import styled, { css } from 'styled-components';

/**
 * @props [
 *    background: String,
 *    inputColor: String,
 *    placeholderColor: String,
 *    inputError Boolean:
 * ]
 */

const Input = styled.input.attrs((props) => {
	return {
		background: props.background || '#FFFFFF',
		inputColor: props.inputColor || '#3E3262',
		placeholderColor: props.placeholderColor || '#7C7D87',
		onclick: props.onClick,
	};
})`
	font-family: 'Poppins', sans-serif;
	padding: 11px 17px;
	background: ${(props) => props.background};
	color: ${(props) => props.inputColor};
	border-radius: 5px;
	border: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	outline: none;
	border: 1px solid ${(props) => props.borderColor || props.background};
	text-align: ${(props) => props.textAlign};
	font-weight: 700;
	&:focus::placeholder {
		opacity: 0;
	}
	&::placeholder {
		font-weight: 500;
		color: ${(props) => props.placeholderColor};
		transition: opacity 100ms linear;
	}
	${(props) =>
		props.inputError &&
		css`
			color: #db0c12;
			border: 1px solid #db0c12;
			&::placeholder {
				color: #db0c12;
			}
		`}
`;

export const Text = styled(Input).attrs((props) => {
	return {
		type: 'text',
		placeholder: props.placeholder || 'Complete este campo',
	};
})``;

export const Password = styled(Input).attrs((props) => {
	return {
		type: 'password',
		placeholder: 'Ingresa tu clave secreta',
	};
})``;

export const Select = styled.select`
	font-family: 'Poppins', sans-serif;
	padding: 11px 17px;
	background: ${(props) => props.background};
	color: ${(props) => props.inputColor};
	border-radius: 5px;
	border: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	outline: none;
	border: 1px solid ${(props) => props.background};
	font-weight: 700;
	&:focus::placeholder {
		opacity: 0;
	}
	&::placeholder {
		font-weight: 500;
		color: ${(props) => props.placeholderColor};
		transition: opacity 100ms linear;
	}
	${(props) =>
		props.inputError &&
		css`
			color: #db0c12;
			border: 1px solid #db0c12;
			&::placeholder {
				color: #db0c12;
			}
		`}
	option {
		color: black;
		background: white;
		display: flex;
		white-space: pre;
		min-height: 20px;
		padding: 0px 2px 1px;
	}
`;

export const TextArea = styled.textarea`
	font-family: inherit;
	resize: none;
	border-radius: 15px;
	border: none;
	padding: 0.8rem;
	height: 30vh;
	outline: none;
	::placeholder {
		color: #53453E;
		font-family: 'Poppins', sans-serif;
		font-style: italic;
		font-weight: normal;
	}
`;
