// import React, { Fragment } from 'react'
// import { Text } from 'components/StyledComponents';
// import { Container, LoaderIcon } from './styles'
// import { Modal } from 'portals/Modal';

// const Loading = () => (
//   <Modal>
//     <Container>
//       <LoaderIcon />
//       <Text.Paragraph>Cargando...</Text.Paragraph>
//     </Container>
//   </Modal>
// )

// export default Loading;

import React from "react";
import { motion } from "framer-motion";

const Loader = ({ circleColor, small }) => {
  const loadingContainer = {
    width: small ? "2rem" : "4rem",
    height: "100%",
    display: "inline-flex",
    justifyContent: "space-around",
    alignItems: "center",
  };
  const loadingCircle = {
    display: "block",
    width: small ? "0.3rem" : "0.5rem",
    height: small ? "0.3rem" : "0.5rem",
    backgroundColor: circleColor || "#fff",
    borderRadius: "0.5rem",
  };

  const loadingContainerVariants = {
    start: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    end: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const loadingCircleVariants = {
    start: {
      y: "0%",
    },
    end: {
      y: "90%",
    },
  };
  const loadingCircleTransition = {
    duration: 0.4,
    yoyo: Infinity,
    ease: "easeInOut",
  };
  return (
    <div>
      <div className="fixed  w-full min-h-screen z-50 bg-black opacity-30" />
      <div className="flex fixed w-full justify-center items-center h-screen">
        <motion.div
          style={loadingContainer}
          variants={loadingContainerVariants}
          initial="start"
          animate="end"
        >
          <motion.span
            style={loadingCircle}
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
          ></motion.span>
          <motion.span
            style={loadingCircle}
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
          ></motion.span>
          <motion.span
            style={loadingCircle}
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
          ></motion.span>
        </motion.div>
      </div>
    </div>
  );
};

export default Loader;
