import styled from "styled-components";

export const UserName = styled.h4`
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const UserRol = styled.h5`
  font-size: 0.8rem;
  font-weight: 300;
`;

export const Chat = styled.p`
  color: ${(props) => props.color || "#fff"};
  font-weight: 300;
  font-size: 1em;
  text-align: ${(props) => props.align || "center"};
`;

export const Paragraph = styled.p`
  color: ${(props) => props.color || "#fff"};
  font-weight: ${(props) => props.fontWeight || "600"};
  /* font-size: ${(props) => props.fontSize || "1em"}; */
  font-size: ${(props) => props.fontSize || "clamp(0.9rem, 2vw, 1.2rem)"};
  margin: ${(props) => props.margin};
  text-align: ${(props) => props.textAlign || "start"};
  ${(props) => props.isDisabled && "opacity: 0.54;"};
  width: ${(props) => props.width || "fit-content"};
  align-self: ${(props) => (props.sender === "user" ? "end" : "start")};
  @media(min-width: 900px){
    font-size: 1.2rem;
  }
`;

export const Headline1 = styled.h1`
  text-align: ${(props) => props.textAlign || "start"};
  font-size: 2.571rem;
  margin: ${(props) => props.margin};
`;

export const Headline2 = styled.h2`
  text-align: ${(props) => props.textAlign || "start"};
  font-size: 3rem;
  margin: ${(props) => props.margin};
`;

export const Headline3 = styled.h3`
  text-align: ${(props) => props.textAlign || "start"};
  font-size: 2rem;
  margin: ${(props) => props.margin};
`;

export const Span = styled.span`
  font-weight: ${(props) => props.fontWeight || "700"};
  text-align: ${(props) => props.textAlign || "start"};
  font-size: ${(props) => props.fontSize || "1.8rem"};
  color: ${(props) => props.color || "1rem"};
  line-height: ${(props) => props.lineHeight || "1rem"};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  opacity: ${(props) => props.opacity};
  display: ${(props) => props.display};
  word-spacing: ${(props) => props.wordSpacing};
`;

export const FormError = styled(Span)`
  color: #ff0000;
  font-weight: 500;
`;

export const SpanHotelName = styled(Span)`
  font-size: clamp(1.5rem, 10vw, 3rem);
  font-weight: 700;
  color: transparent;
  font-family: "Barlow", sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight};`}
`;
