/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from "react";
import { Container, Text, Image } from "components/StyledComponents";
import { Content, ContentOverflow, ItemWrapper, Item } from "./itemContentStyled";
import Loader from "components/Loading";
import useStore from "context/store";

const initThings = {
  windows: {
    between: [1, 10],
  },
  parchments: {
    between: [11, 14],
  },
  benefits: {
    between: [15, 34],
  },
};

const ItemContent = ({ categorySelected }) => {
  const rewards = useStore((state) => state.rewards);
  const { rewards: userRewards } = useStore((state) => state.user);

  const [loading, setLoading] = useState(true);
  const LOAD_IMAGE = useCallback(
    (nameImage) => require(`assets/images/bag/things/${nameImage}.png`),
    []
  );
  const [imageThings, setImageThings] = useState([]);

  useEffect(() => {
    listImages();
  }, [categorySelected, userRewards]);

  const listImages = () => {
    setLoading(true);
    const { between } = initThings[categorySelected];
    const indexedThings = rewards.reduce(
      (acc, item) => ({ ...acc, [item.id]: item }),
      {}
    );
    const thingsObtained = userRewards?.map(({ id }) => id);
    setImageThings([]);
      for (let id = between[0]; id <= between[1]; id++) {
        let nameImage = id.toString();
        let state = thingsObtained.includes(id);
        let nameImageComplete = `${nameImage}${!state ? "-inactivo" : ""}`;
        let image = LOAD_IMAGE(nameImageComplete);
        setImageThings((prev) => [...prev, { ...indexedThings[id], image, state }]);

    }
    setLoading(false);
  };

  return (
    <Content>
      <ContentOverflow>
        {loading ? (
          <Loader />
        ) : (
          imageThings.length > 0 &&
          imageThings.map((thing, index) => (
            <ItemWrapper key={index} active={thing.state}>
              <Item childrenImageSize={thing.id < 11 ? "45px" : "60px"}>
                <Image.Default src={thing.image} alt="Not found" />
                {thing.id < 15 && (
                  <Container.Block
                    zIndex="10"
                    textAlign="center"
                    width="55px"
                    marginBlockEnd="0"
                    lineHeight=".7"
                  >
                    <Text.Span
                      color="#fff"
                      opacity={!thing.state ? ".3" : "1"}
                      fontSize="8px"
                      textAlign="center"
                      lineHeight="1"
                    >
                      {thing.name}
                    </Text.Span>
                  </Container.Block>
                )}
              </Item>
            </ItemWrapper>
          ))
        )}
      </ContentOverflow>
    </Content>
  );
};

export default ItemContent;
