/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ListRoutes from "./routes";
import useStore from "context/store";
import Login from "views/login";
import Portal from "portals/Portal";
import Bag from "views/Bag";
import ParchmentFinal from "views/ParchmentFinal";


const App = () => {

  const auth = useStore((state) => state.auth);
  const setStore = useStore((state) => state.setStore);
  const showBag = useStore((state) => state.showBag);
  const showFinalMessage = useStore((state) => state.showFinalMessage);

  useEffect(() => {
    if (auth) {
      setStore();
    }
  }, [auth]);

  if (!auth) {
    return <Login />;
  }

  return (
    <>
      <ListRoutes />
      <Portal show={showBag}>
        <Bag />
      </Portal>
      <Portal show={showFinalMessage}>
        <ParchmentFinal
        // onHide={setShowFinalMessage}
        />
      </Portal>

    </>
  );
};

export default App;
