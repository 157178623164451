import styled, { keyframes } from 'styled-components';
// const animationIcon = keyframes`
//   from {
//     transform: scale(0);
//   }
//   to {
//     transform: scale(1.2);
//   }
// `

export const BgPrealoder = styled.div`
  background-image: linear-gradient(#01FEFF, #9D00FF);
  inline-size: 100vw;
  block-size: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

// export const Figure = styled.figure`
//   animation: 1.3s ease-in-out 0s infinite ${animationIcon};
// `