export const getItem = (key) => {
  const item = localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return null;
  // return {};
};

export const setItem = (key, newData) => {
  const item = getItem(key) || {};
  const newItem = { ...item, ...newData };
  return localStorage.setItem(key, JSON.stringify(newItem));
};
