import axios from "axios"
import { API_VERSION } from "./config";

const SuitecaseService = {};

SuitecaseService.getAll = async () => {
  const response = await axios.get(`${API_VERSION}things`);
  return response.data;
}

SuitecaseService.getMaps = async () => {
  const response = await axios.get(`${API_VERSION}categories/2/things`);
  return response.data;
}


export default SuitecaseService;