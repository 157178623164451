import styled from "styled-components";

export const ImageWrapper = styled.div`
  width: 90vw;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  video {
    max-width: 100%;
    border-radius: 15px;
    /* aspect-ratio: 1; */
    object-fit: contain;
    max-height: 90vh;
  }
  @media (min-width: 425px) {
		width: 50vw;
	}
`;

export const Image = styled.img`
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius};`}
  filter: ${(props) => props.filter}
`;

export const IconWrapper = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 50%;
  color: #000;
  font-weight: bold;
  font-size: 1.2rem;
  align-self: end;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.2rem;
`;

export const IconClose = styled(IconWrapper)`
  width: 35px;
  height: 35px;
  margin: auto;
`;

export const IconPlus = styled(IconWrapper)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 45%;
`;
